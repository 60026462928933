<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Use the SDS available
        <a
          href="https://www.flinnsci.com/sds_845-water/sds_845/"
          rel="noopener noreferrer"
          target="_blank"
          >here</a
        >, to help you answer the following questions about
        <stemble-latex content="$\ce{H2O}." />
      </p>

      <p class="mb-2">a) What is the name of the chemical according to the SDS?</p>

      <v-text-field
        v-model="inputs.compoundName"
        outlined
        class="mb-n2"
        :disabled="!allowEditing"
      />

      <p class="mb-2">b) What is the chemical formula?</p>

      <chemical-notation-input v-model="inputs.molFormula" class="mb-5" :disabled="!allowEditing" />

      <p class="mb-2">c) What is the molecular weight of the compound?</p>

      <calculation-input
        v-model="inputs.molWeight"
        class="mb-5"
        prepend-text="$\text{Molecular Weight}:$"
        append-text="$\text{amu}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">d) What is the density of the compound at 20<sup>o</sup>C?</p>

      <calculation-input
        v-model="inputs.density"
        class="mb-5"
        prepend-text="$\text{Density}:$"
        append-text="$\text{g/mL}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">e) What <b>hazards</b> does the chemical pose?</p>

      <p v-for="option in options1" :key="'pt-5-' + option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.hazards" :value="option.value" class="pl-8 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>

      <p class="mb-n2 mt-5">
        f) Choose the <b>best answer</b> below for how to dispose of the chemical?
      </p>

      <v-radio-group v-model="inputs.disposal" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options2"
          :key="'pt-6-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput';
import CalculationInput from '@/tasks/components/inputs/CalculationInput';

export default {
  name: 'SdsWater',
  components: {StembleLatex, ChemicalNotationInput, CalculationInput},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        compoundName: null,
        molFormula: null,
        molWeight: null,
        density: null,
        hazards: [],
        disposal: null,
      },
      options1: [
        {text: '$\\text{Flammable}$', value: 'a'},
        {text: '$\\text{Skin irritant}$', value: 'b'},
        {text: '$\\text{Eye irritant}$', value: 'c'},
        {text: '$\\text{Health hazard}$', value: 'd'},
        {text: '$\\text{Do not inhale}$', value: 'e'},
        {text: '$\\text{None of the above}$', value: 'f'},
      ],
      options2: [
        {text: '$\\text{According to instructions}$', value: 'a'},
        {text: '$\\text{Down the drain}$', value: 'b'},
        {text: '$\\text{Other}$', value: 'c'},
      ],
    };
  },
};
</script>
<style scoped>
.v-input--checkbox {
  display: inline-block !important;
}
</style>
